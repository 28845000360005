<!--  -->
<template>
  <div>
    <div class="banner">
      <!-- Swiper -->
      <div class="swiper mySwiper" v-if="slideImgs.length>1">
        <div class="swiper-wrapper">
          
          <div class="swiper-slide" v-for="(item, i) in slideImgs" :key="i">
            <img :src="item" alt="" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <img :src="slideImgs[0]" alt="" v-else>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";  // 所有 Swiper 样式，包括所有模块样式（如导航、分页等）
import Swiper, { Navigation, Pagination, Autoplay } from "swiper"; // 导入您需要的模块
export default {
  name: "HelloWorld",
    props:{
      slideImgs:{
          default:()=>[]
      }
    },
  data() {
    return {
     
    };
  },
  mounted() {
    new Swiper(".mySwiper", {
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      modules: [Navigation, Pagination, Autoplay],
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
};
</script>

<style lang='scss' scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>